import { useCallback, useMemo, useRef, useState } from 'react'
import { useApolloClient } from 'apollo-client'
import logger from 'logger'
import cookieStorage from 'cookie-storage'
import localStorage from 'local-storage'
import dayjs from 'date'
import { constants, GraphQLError, UserError } from 'helpers'
import { useLocalePreferences } from 'modules/localePreferences'
import type { UserGender, AnalyticsMetadataInput } from 'typings/graphql'

import LocalePreferencesFragmentDoc from '../fragments/localePreferences.graphql'
import userLoginQuery, { type UserLoginVariables } from './graph/userLogin.graphql'
import registerQuery, { type UserRegisterVariables } from './graph/userRegister.graphql'
import userFacebookSignInQuery, { type UserFacebookSignInVariables } from './graph/userFacebookSignIn.graphql'
import userGoogleSignInQuery, { type UserGoogleSignInVariables } from './graph/userGoogleSignIn.graphql'
import userAppleSignInQuery, { type UserAppleSignInVariables } from './graph/userAppleSignIn.graphql'
import userMagicLinkCreateQuery, { type UserMagicLinkCreateVariables } from './graph/userMagicLinkCreate.graphql'


/**
 * We send freeTrial property on registration to the backend
 * It passes this property to the iterable
 * This needed to send free trial offer by email instead of 25off
 *
 * @returns {boolean} Returns true if a user from free trial flow
 */
const getIsFromFreeTrialLanding = () => Boolean(cookieStorage.getItem(constants.cookieNames.isFreeTrialCoupon))

const getSelectedGender = (): UserGender => localStorage.getSessionItem(constants.localStorageNames.authGender) || 'FEMALE'

const getSignUpMetadata = (): AnalyticsMetadataInput => {
  const attributes: AnalyticsMetadataInput['attributes'] = [
    {
      key: 'COUPON_CODE',
      value: cookieStorage.getItem(constants.cookieNames.discountCoupon),
    },
  ]

  // return only filled attributes
  return {
    attributes: attributes.filter(({ value }) => Boolean(value)),
  }
}

interface AuthCallback {
  (...args: any[]): Promise<any>
}

const useAuthQuery = <T extends AuthCallback>(callback: T) => {
  const client = useApolloClient('default')
  const localePreferencesContext = useLocalePreferences()
  const localeReferencesContextRef = useRef(localePreferencesContext)
  localeReferencesContextRef.current = localePreferencesContext

  const [ isFetching, setFetching ] = useState(false)

  const submit = useCallback(async (...args: Parameters<T>): Promise<Awaited<ReturnType<T>>> => {
    try {
      setFetching(true)

      const result = await callback(...args)

      // if auth succeeded
      if (result) {
        // ft, ab tests
        await client.refetchQueries({
          include: [ 'AbFt' ],
        })

        // try to get user's locale preferences
        try {
          const userLocalePreferences = client.cache.readFragment({
            id: 'UserData:{}',
            fragment: LocalePreferencesFragmentDoc,
          })?.localePreferences

          const { setLocalePreferences } = localeReferencesContextRef.current
          setLocalePreferences(() => userLocalePreferences as Intl.LocalePreferences)
        }
        catch (error) {
          logger.error(error, 'Failed to sync user locale preferences')
        }

        // evict user-specific data, it will be requested if needed
        client.cache.evict({ id: 'UserData:{}', fieldName: 'cart' })
        client.cache.evict({ id: 'UserData:{}', fieldName: 'queue' })
      }

      return result
    }
    catch (error) {
      // reset only on error, because on success there will be a redirect
      setFetching(false)
      throw error
    }
  }, [ callback, client ])

  return [
    submit,
    {
      isFetching,
    },
  ] as const
}


export const useLogIn = () => {
  const client = useApolloClient()

  const mutate = useCallback(async (input: UserLoginVariables['input']) => {
    const result = await client.mutate({
      mutation: userLoginQuery,
      variables: {
        input,
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.userLogIn

    if (error) {
      throw new UserError(error)
    }

    return data
  }, [ client ])

  return useAuthQuery(mutate)
}


export const useRegister = () => {
  const client = useApolloClient()

  const mutate = useCallback(async (input: UserRegisterVariables['input']) => {
    const result = await client.mutate({
      mutation: registerQuery,
      variables: {
        input: {
          freeTrial: getIsFromFreeTrialLanding(),
          metadata: getSignUpMetadata(),
          growthbookUid: cookieStorage.getItem(constants.cookieNames.growthbookUid),
          ...input,
        },
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.userRegister

    if (error) {
      throw new UserError(error)
    }

    return data
  }, [ client ])

  return useAuthQuery(mutate)
}

// little helper to identify that account has been created recently
export const isNewUser = (registrationDate: string) => {
  try {
    return dayjs().diff(registrationDate, 'second') < 60
  }
  catch (error) {
    logger.error(error)
    return true
  }
}

export const useFacebookSignIn = () => {
  const client = useApolloClient()

  const mutate = useCallback(async (input: UserFacebookSignInVariables['input']) => {
    const result = await client.mutate({
      mutation: userFacebookSignInQuery,
      variables: {
        input: {
          freeTrial: getIsFromFreeTrialLanding(),
          gender: getSelectedGender(),
          metadata: getSignUpMetadata(),
          growthbookUid: cookieStorage.getItem(constants.cookieNames.growthbookUid),
          ...input,
        },
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.userFacebookSignIn

    if (error) {
      throw new UserError(error)
    }

    return {
      data,
      isNewUser: isNewUser(data.userData?.personalInfo?.registrationDate),
    }
  }, [ client ])

  return useAuthQuery(mutate)
}

export const useGoogleSignIn = () => {
  const client = useApolloClient()

  const mutate = useCallback(async (input: UserGoogleSignInVariables['input']) => {
    const result = await client.mutate({
      mutation: userGoogleSignInQuery,
      variables: {
        input: {
          freeTrial: getIsFromFreeTrialLanding(),
          gender: getSelectedGender(),
          metadata: getSignUpMetadata(),
          growthbookUid: cookieStorage.getItem(constants.cookieNames.growthbookUid),
          ...input,
        },
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.userGoogleSignIn

    if (error) {
      throw new UserError(error)
    }

    return {
      data,
      isNewUser: isNewUser(data.userData?.personalInfo?.registrationDate),
    }
  }, [ client ])

  return useAuthQuery(mutate)
}

export const useAppleSignIn = () => {
  const client = useApolloClient()

  const submit = useCallback(async (input: UserAppleSignInVariables['input']) => {
    const result = await client.mutate({
      mutation: userAppleSignInQuery,
      variables: {
        input: {
          freeTrial: getIsFromFreeTrialLanding(),
          metadata: getSignUpMetadata(),
          growthbookUid: cookieStorage.getItem(constants.cookieNames.growthbookUid),
          ...input,
        },
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.userAppleSignIn

    if (error) {
      throw new UserError(error)
    }

    return {
      data,
      isNewUser: isNewUser(data.userData?.personalInfo?.registrationDate),
    }
  }, [ client ])

  return useAuthQuery(submit)
}

export const useMagicLinkCreate = () => {
  const client = useApolloClient()
  const [ isFetching, setFetching ] = useState(false)

  const mutate = useCallback(async (input: UserMagicLinkCreateVariables['input']) => {
    try {
      setFetching(true)
      const result = await client.mutate({
        mutation: userMagicLinkCreateQuery,
        variables: {
          input,
        },
      })

      if (result.errors) {
        throw new GraphQLError(result.errors)
      }

      const { error } = result.data.userMagicLinkCreate

      if (error && error.__typename !== 'UserMagicLinkError') {
        throw new UserError(error)
      }
    }
    catch (error) {
      throw error
    }
    finally {
      setFetching(false)
    }
  }, [ client ])

  return [
    mutate,
    {
      isFetching,
    },
  ] as const
}
